import * as React from "react"
import '../styles/global.scss';
import Layout from "../components/layout";

export default class PrivacyPage extends React.Component {
    public render() {
        return (
            <Layout isHomePage={false}>
                <div className={'row mt-4'}>
                    <div className={'col-12'}>

                        <h2>Regulamin aplikacji mobilnej Loggy</h2>

                        <h3>I. POSTANOWIENIA OGÓLNE</h3>

                        <p>1. Niniejszy regulamin – zwany dalej Regulaminem aplikacji Loggy określa zasady
                            korzystania z aplikacji mobilnej Loggy.</p>

                        <p>2. W zakresie nieuregulowanym Regulaminem aplikacji mobilnej Loggy stosuje się
                            Regulamin ogólny korzystania przez Licencjobiorcę z platformy „LOGGY”.</p>

                        <p>3. Użytkownik oświadcza, że zapoznał się z Regulaminem ogólnym i akceptuje jego
                            warunki.</p>
                        <p>4. Użytkownik oświadcza, że zapoznał się z Regulaminem aplikacji mobilnej Loggy i
                            akceptuje jego warunki.</p>

                        <p>5. Zmiana Regulaminu aplikacji mobilnej Loggy nie stanowi zmiany regulaminu
                            ogólnego.</p>

                        <h3>II. SŁOWNICZEK POJĘĆ (Definicje regulaminowe)</h3>

                        <p>1. Aplikacja Loggy – oprogramowanie pod nazwą Loggy spełniające cechy
                            wskazane w pkt. I ppkt 7 Regulaminu ogólnego, dostarczane przez
                            Usługodawcę, instalowane w pamięci Urządzenia mobilnego,
                            funkcjonujące na Platformie Loggy w rozumieniu w pkt. I ppkt 6
                            Regulaminu ogólnego</p>

                        <p>2. Platforma Loggy – oprogramowanie pod nazwą Platforma Loggy
                            spełniające cechy wskazane w pkt. I ppkt 6 Regulaminu ogólnego
                            - stanowiące otoczenie informatyczne, na którym funkcjonuje Aplikacja
                            Loggy, zarządzana przez Usługodawcę.</p>

                        <p>3. Użytkownik – osoba fizyczna nie będąca konsumentem, która korzysta z
                            aplikacji Loggy.</p>

                        <p>4. Usługodawca – LOGGY SPÓŁA Z OGRANICZONA ODPOWIEDZIALNOSCIA
                            - z siedzibą w Warszawie, ul. Świeradowska 47, 02-662 Warszawa,
                            wpisaną do rejestru przedsiębiorców, prowadzonego przez Sąd Rejonowy
                            dla m.st. Warszawy, Wydział XIII Gospodarczy Krajowego Rejestru
                            Sądowego pod nr KRS 0000802199, o kapitale zakładowym w wysokości
                            5.000,00 złotych NIP: 521-387-55-58, REGON 384286533.</p>

                        <p>5. Urządzenie mobilne - (przenośne) urządzenie elektroniczne pozwalające
                            na przetwarzanie, odbieranie oraz wysyłanie danych – w szczególności
                            takie jak smartfon, smartwach, tablet, telefon komórkowy, laptop.</p>

                        <h3>III. ZASADY KORZYSTANIA Z APLIKACJI</h3>

                        <p>1. Usługi z wykorzystaniem Aplikacji są świadczone 24 godziny na dobę, 7 dni w
                            tygodniu, na żądanie Użytkownika.</p>

                        <p>2. Usługodawca nie jest dostawcą usług transmisji danych.</p>

                        <p>3. Koszty transmisji danych wymaganych do pobrania, instalacji, uruchomienia i
                            korzystania z Aplikacji pokrywają jej Użytkownicy we własnym zakresie.</p>

                        <p>4. Usługodawca nie ponosi odpowiedzialności za wysokość opłat naliczanych z
                            tytułu wykorzystania transmisji danych niezbędnych do korzystania z Aplikacji.</p>

                        <p>
                            5. Usługodawca nie ponosi odpowiedzialności za nieprawidłowe działanie Aplikacji
                            Loggy w przypadku nie spełnienia przez Użytkownika wymogów technicznych
                            wymaganych do pobrania, instalacji, uruchomienia i korzystania z Aplikacji
                            Loggy.</p>

                        <p>6. Usługodawca nie ponosi odpowiedzialności za szkody spowodowane
                            nieprawidłowym działaniem Aplikacji Loggy, w tym za szkody zawinione.</p>

                        <p>7. Usługodawca nie ponosi odpowiedzialności za szkody spowodowane
                            nieprawidłowym użytkowaniem Aplikacji Loggy przez Użytkownika.</p>

                        <p>8. Użytkownicy są zobowiązani do korzystania z Aplikacji Loggy w sposób zgodny z
                            zasadami bezpieczeństwa, w tym bezpieczeństwa w ruchu lądowym oraz z
                            obowiązującym prawem, Regulaminem ogólnym i Regulaminem aplikacji Loggy.</p>

                        <p>9. Użytkownicy są zobowiązani niezwłocznie powiadomić Usługodawcę o każdym
                            przypadku naruszenia jego praw przez osoby trzecie w związku z korzystaniem
                            z Aplikacji Loggy.</p>

                        <p>10. W przypadku stwierdzenia, że Użytkownik dopuszcza się działań zabronionych
                            prawem lub Regulaminami, albo naruszających zasady współżycia społecznego
                            może niezwłocznie pozbawic Użytkownika dostępu do Aplikacji Loggy.</p>
                        <p>11. Usługodawca nie ponosi odpowiedzialności za korzystanie przez Użytkownika
                            z Aplikacji w sposób sprzeczny z postanowieniami Regulaminów.</p>
                        <p>12. Odpowiedzialność Usługodawcy za ewentualne szkody powstałe w związku
                            z korzystaniem z Aplikacji, a w szczególności za brak jej funkcjonowania, a także
                            nieprawidłowe funkcjonowanie - wyłączona jest w możliwie najszerszym,
                            prawnie dopuszczalnym zakresie.</p>
                        <p>13. Usługodawca ma prawo zablokować dostęp do Aplikacji lub poszczególnych
                            Usług w razie stwierdzenia nieprawidłowości w korzystaniu z Aplikacji.</p>

                        <p>14. Korzystanie z Aplikacji w żaden sposób nie zwalnia Użytkownika z obowiązku
                            przestrzegania przepisów prawa i zasad współżycia społecznego w szczególności
                            przepisów dotyczących ruchu drogowego. Usługodawca nie może być
                            pociągnięty do odpowiedzialności tak cywilnej jak i karnej za ich złamanie przez
                            Użytkownika.</p>

                        <h3>IV. POSTANOWIENIA KOŃCOWE</h3>
                        <p>1. Niniejszy Regulamin wchodzi w życie z dniem 01.12.2019</p>
                    </div>

                </div>
            </Layout>
        )
    }
}
